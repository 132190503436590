<template>
  <div id="roles-audits-list" class="m-2">
    <b-table
        hover
        striped
        show-empty
        responsive="sm"
        primary-key="id"
        className="position-relative mb-0"
        :items="audits"
        :fields="tableColumns"
        empty-text="No existen datos">
      <template #cell(user)="data">
        {{ data.item.user.name }} {{ data.item.user.last_name }}
      </template>
      <template #cell(created_at)="data">
        {{ data.item.created_at | moment("DD-MM-YYYY") }}
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'ProjectsAuditsList',
  props: ['audits'],
  data() {
    return {
      selectAudit: '',
      tableColumns: [
        {key: 'id', sortable: false, label: 'ID'},
        {key: 'user', sortable: false, label: 'Autor'},
        {key: 'created_at', sortable: false, label: 'Fecha'},
        {key: 'event', sortable: false, label: 'Evento'},
        {key: 'old_values', sortable: false, label: 'Valor Anterior'},
        {key: 'new_values', sortable: false, label: 'Valor Nuevo'},
      ]
    }
  },
}
</script>
