<template>
  <div id="webhooks-audits">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <h5>Nombre Webhook</h5>
            <b-row>
              <b-col xl="3" sm="12">
                <b-form-input id="name"
                  v-model="nameWebhook"
                  name="name"
                  disabled />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <projects-webhooks-audits-list :audits="audits"></projects-webhooks-audits-list>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import ProjectsWebhooksAuditsList from './components/ProjectsWebhooksAuditsList'

  export default{
    name: 'WebhooksAudits',
    components: {ProjectsWebhooksAuditsList},
    props: ['audits'],
    data() {
      return {
      }
    },
    computed: {
      nameWebhook () {
        return this.audits ? `${this.audits.name}` : ''
      },
    },
  }
</script>